/* eslint-disable react/boolean-prop-naming */
import React from 'react';
import PropTypes from 'prop-types';
import WithSmartEdit from 'cms/WithSmartEdit';
import NewsletterForm from 'components/NewsletterForm';

const CmsTrackedNewsletterComponent = ({
  activateCheckboxes,
  disableMarketingCheckbox,
  ...rest
}) => (
  <NewsletterForm
    {...rest}
    withActivatedCheckboxes={activateCheckboxes}
    withDisabledMarketingCheckbox={disableMarketingCheckbox}
  />
);

CmsTrackedNewsletterComponent.propTypes = {
  activateCheckboxes: PropTypes.bool,
  disableMarketingCheckbox: PropTypes.bool,
};

CmsTrackedNewsletterComponent.defaultProps = {
  activateCheckboxes: false,
  disableMarketingCheckbox: false,
};

export default WithSmartEdit(CmsTrackedNewsletterComponent);
